<template>
	<Dialog v-model:visible="visible" maximizable modal  header="사용자 정보 등록" :style="{ width: '35vw' }" @update:visible="$emit('closesModal'); visible = false;">
		  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
	      	<Divider align="left" type="solid">
		    	<b>이름</b>
			</Divider>
			<p>
			    <input type="text" class="form-control" placeholder="한글로 작성해주세요." v-model="name" />
			</p>
			<Divider align="left" type="solid">
		    	<b>이메일</b>
			</Divider>
			<p>
		        <input type="text" class="form-control" placeholder="영문으로 작성해주세요." v-model="email"/>
			</p>
			<Divider align="left" type="solid">
		    	<b>회사명</b>
			</Divider>
			<p>
		        <Dropdown v-model="company" scroll-height="500px" :options="companys" optionLabel="name" placeholder="회사명" class="w-full md:w-14rem" />  
			</p>
			<Divider align="left" type="solid">
		    	<b>직급</b>
			</Divider>
			<p>
		        <Dropdown v-model="position" scroll-height="500px" :options="positions" optionLabel="name" placeholder="직급" class="w-full md:w-14rem" />  
			</p>
			<Divider align="left" type="solid">
		    	<b>부서(실)</b>
			</Divider>
			<p>
		        <Dropdown v-model="department" scroll-height="500px" :options="departments" optionLabel="name" placeholder="부서(실)" class="w-full md:w-14rem" @change="team=''"/>  
			</p>
			<Divider align="left" type="solid">
		    	<b>팀 이름</b>
			</Divider>
			<p>
		        <Dropdown v-model="team" scroll-height="500px" :options="filteredTeams" optionLabel="name" placeholder="부서(실)을 먼저 선택해주세요." class="w-full md:w-14rem"/>  
			</p>			
			<Divider align="left" type="solid">
		    	<b>사용자 여부</b>
			</Divider>
			<p>
			    <input class="ms-2" type="checkbox" id="userCheck" v-model="useYn" true-value="Y" false-value="N" />
			    <label for="userCheck">적용</label>
			</p>
			<Divider align="left" type="solid">
		    	<b>관리자 여부</b>
			</Divider>
			<p>
			    <input class="ms-2" type="checkbox" id="managerCheck" v-model="adminYn" true-value="Y" false-value="N"/>
			    <label for="managerCheck">적용</label>
			</p>
			
	      </div>
	      <template #footer>
	        <Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
	        <Button label="등록" icon="pi pi-check" @click="regist" autofocus />
	    </template>
	</Dialog>
</template>

<script>
export default {
  name: 'registModal',
  data(){
    return{
      name: "",
      email: "",
      useYn: "",
      adminYn: "",      
	  company: "",
	  position: "",
	  department: "",
	  team: "",	  
      visible: true,
	  companys: [
		// {
		// 	name: '클립',
		// },
		{
			name: '한컴이노스트림',
		},
		{
			name: '라움',
		},
	  ],
	  positions: [
		{
			name: '대표이사',
		},
		{
			name: '상무이사',
		},
		{
			name: '이사',
		},
		{
			name: '부장',
		},
		{
			name: '차장',
		},
		{
			name: '과장',
		},
		{
			name: '대리',
		},
		{
			name: '주임',
		},
		{
			name: '사원',
		},
	  ],
	  departments: [
		{
			name: '대표이사실',
			teams: [
				{ name: '', code: 'CEO'},
			]
		},
		{
			name: '제품지원실',			
			teams: [
				{ name: '교육품질팀', code: 'EQT'},
				{ name: '원격지원팀', code: 'RST'},
			]
		},
		{
			name: '연구소',			
			teams: [
				{ name: '코어팀', code: 'RND'},
				{ name: '뷰어팀', code: 'RND'},
			]
		},
		{
			name: '제품개발실',		
			teams: [
				{ name: '제품개발팀', code: 'PDT'},
			]	
		},
		{
			name: '영업1실',			
			teams: [
				{ name: '기엽영업팀', code: 'SLS'},
			]
		},
		{
			name: '영업2실',	
			teams: [
				{ name: 'SI영업팀', code: 'SLS'},
				{ name: 'MA사업팀', code: 'SLS'},
			]		
		},
		{
			name: 'SI사업실',	
			teams: [
				{ name: 'SI1팀', code: 'SI1'},
				{ name: 'SI2팀', code: 'SI2'},
				{ name: 'SI3팀', code: 'SI3'},
			]						
		},
		{
			name: '기술실',			
			teams: [
				{ name: '공공팀', code: 'TST'},
				{ name: '기업팀', code: 'TST'},
				{ name: 'MA점검팀', code: 'MAC'},
			]						
		},
		{
			name: 'BI사업실',
			teams: [
				{ name: '컨설팅팀', code: 'BIC'},
				{ name: 'BA1팀', code: 'BA1'},
				{ name: 'BA2팀', code: 'BA2'},
				{ name: 'BA3팀', code: 'BA3'},
				{ name: 'BA4팀', code: 'BA4'},
			]						
		},
		{
			name: '전략기획실',
			teams: [
				{ name: 'UIUX팀', code: 'UIX'},
				{ name: '프리세일즈팀', code: 'PSS'},
				{ name: '브랜딩팀', code: 'BRD'},
			]
		},
		{
			name: '영업지원실',
			teams: [
				{ name: '영업지원팀', code: 'SST'},
			]
		},
		{
			name: '경영지원실',
			teams: [
				{ name: '재무팀', code: 'MMS'},
				{ name: '인사총무팀', code: 'MMS'},
			]
		},
	  ],	  
    }
  },
  methods: {
    regist () {
      	if(confirm("사용자 정보를 등록하시겠습니까?")){
	        if(this.name == "" || this.email == "") {
				this.$messageShow('이름, 이메일주소는 필수입력항목입니다.', 'warn');
	        } else if(this.company.name == undefined) {
				this.$messageShow('회사명을 선택해주세요.', 'warn');
			} else if(this.position.name == undefined) {
				this.$messageShow('직급을 선택해주세요.', 'warn');
			} else if(this.department.name == undefined) {
				this.$messageShow('부서(실)을 선택해주세요.', 'warn');
			} else if(this.team.name == undefined) {
				this.$messageShow('팀을 선택해주세요.', 'warn');
			} else{
	        
		        if(!this.$emailValidation(this.email)){
		        	this.$messageShow('이메일주소가 형식에 맞지 않습니다.', 'warn');
		        }else{
		        
			        var param = {
				        "userEmail": this.email,
				        "name": this.name,
				        "useYn": this.useYn,
				        "adminYn": this.adminYn,
						"company": this.company.name,
						"position": this.position.name,
						"department": this.department.name,
						"team": this.team.name,
						"teamCode": this.team.code,
					};
					
					this.$post('/biz/account/add', param,
		                function(response, i){
		                	//alert("사용자 정보 등록이 완료되었습니다.");
		                	i.$messageShow('사용자 정보 등록이 완료되었습니다.', 'success');
		                	i.visible = false;
		                	i.$emit('closesModal');
	          				i.$emit('update');
		                },
		                function(e, i){
		                	i.$messageShow(e, 'danger');
		                }
		      		);
			 	}	        
			}
	    }
    },
  },  
  computed: {
    // 선택된 부서의 팀 목록을 계산
    filteredTeams() {
      if (this.department) {
        return this.department.teams || [];
      }
      return "";
    },
  },
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>