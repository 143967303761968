<template>
  <transition name="fade" @closesModal="licenseIssueModal = false" @update="update = true">
    <LicenseIssue :detailsCompanySeq="detailsCompanySeq" :detailsProjectUid="detailsProjectUid" :detailsLicenseSeq="detailsLicenseSeq" :engineerRoleCheck="engineerRoleCheck" :update="update"  v-if="licenseIssueModal" />    
  </transition>
  
  <transition name="fade" @closesModal="licenseSerialNoUpdateModal = false" @update="update = true">
    <LicenseSerialNoUpdate :detailsCompanySeq="detailsCompanySeq" :detailsProjectUid="detailsProjectUid" :detailsLicenseSeq="detailsLicenseSeq" :detailsLicenseSerialNo="detailsLicenseSerialNo" :engineerRoleCheck="engineerRoleCheck" :update="update" v-if="licenseSerialNoUpdateModal" />
  </transition>

  <transition name="fade" @closesModal="licenseInfoRegistModal = false" @update="update = true">
    <LicenseInfoRegist v-if="licenseInfoRegistModal" :engineerRoleCheck="engineerRoleCheck" />
  </transition>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">라이선스 내역</h1>
  </div>

  <!-- 검색 조건 -->
  <div class="container" v-if="isMobile == false">
    <AutoComplete v-model="searchKeywordCompany" optionLabel="companyName" dropdown placeholder="고객사명"  :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList"/>  
    <AutoComplete v-model="searchKeywordProject" optionLabel="projectName" dropdown placeholder="프로젝트명"  :suggestions="filteredProjects" @complete="projectSearch" @change="setProjectInfo"/>  
    <Dropdown v-model="serverType" editable  :options="serverTypes" optionLabel="label" placeholder="서버타입" class="w-full md:w-14rem" />  
    <Dropdown v-model="ipType" editable  :options="ipTypes" optionLabel="label" placeholder="IP타입" class="w-full md:w-14rem" @change="if(this.ipType != '' && this.ipType.value =='ALL'){ this.searchKeywordIP = '';}"/>  
    <input v-model="searchKeywordIP" :disabled="this.ipType != '' && this.ipType.value =='ALL'" class="form-control" type="text" placeholder="IP"  @keyup.enter="changePage(1, this.pagination.pageSize)" style="width: 400px;"/>      
    <input v-model="searchKeywordLicenseSerialNo" class="form-control" type="text" placeholder="일련번호"  @keyup.enter="changePage(1, this.pagination.pageSize)" style="width: 200px;"/>
            
    <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>
    <Button class="pi pi-times-circle" severity="success" @click="unsetSearchData" @mouseover="setHoverText('검색 초기화')" @mouseout="clearHoverText" :title="hoverText"></Button>  
    <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('새로작성')" @mouseout="clearHoverText" :title="hoverText" v-if="engineerRoleCheck == true" @click="licenseInfoRegistModal = true"></Button>    
    <Button @click="exportXls($event)" style="display:inline-flex; justify-content:center; background:#ffffff; border:1px solid #939599; height:4%; width:4%;">
      <img src="@/assets/excel.svg" alt="Excel Icon" style="width: 25px; height: 25px;" />
    </Button>

  </div>
  
  <!-- 검색 조건 모바일 -->
  <div class="container d-flex" v-if="isMobile == true" >
    <div class="d-flex" style="width:100%;">
	  <input v-model="searchKeyword" class="form-control" type="text" placeholder="프로젝트 / 고객사명 / IP / 일련번호"  @keyup.enter="changePage(1, this.pagination.pageSize)" />
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>
      <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('새로작성')" @mouseout="clearHoverText" :title="hoverText" v-if="engineerRoleCheck == true" @click="licenseInfoRegistModal = true"></Button>    
    </div>
  </div>

  <!-- 본문 -->
  <div class="container" v-if="isMobile == false">	    
	  <table class="table table-bordered table-hover caption-top">
	    <thead class="table-light">
	      <tr class="table-primary">
	        <th scope="col" v-for="item in header" :key="item" class="center">
	          {{ item }}
        </th>
      </tr>
    </thead>
     <tbody class="table-group-divider">
      <tr v-for="(row, i) in license" :key="i"  >
        <td class="center" @click="popupIssueModal(row)" @mouseover="setHoverText(row.companyName)" @mouseout="clearHoverText" :title="hoverText" style="cursor: pointer; text-overflow:ellipsis;">{{ row.companyName.length > 8 ? row.companyName.substring(0,8) + '...' : row.companyName }}</td>
        <td @click="popupIssueModal(row)" @mouseover="setHoverText(row.projectName)" @mouseout="clearHoverText" :title="hoverText" style="cursor: pointer; text-overflow:ellipsis;">{{ row.projectName.length > 8 ? row.projectName.substring(0,8) + '...' : row.projectName }}</td>
        <td @click="popupIssueModal(row)" style="cursor: pointer; text-overflow:ellipsis;" v-html="row.issuedProdName !== null ? row.issuedProdName.replaceAll(',', ',<br/>') : ''"></td>
        <td class="center" @click="popupIssueModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.serverTypeName }}</td>
        <td class="center" @click="popupIssueModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.cpuInfo }}</td>
        <td class="center" style="text-overflow:ellipsis;" v-html="row.ipType !== 'ALL' ? (row.ipAddress !== null ? row.ipAddress.replaceAll(',', ',<br/>') :  '') : row.ipTypeName"></td>                  
        <td class="center" @click="popupIssueModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.periodTypeName == '기간제한' ? row.endDate : row.periodTypeName }}</td>
        <td class="center" @click="popupIssueModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.registerName }}</td>
        <td class="center" @click="popupIssueModal(row)" style="cursor: pointer; text-overflow:ellipsis;" v-html="row.issueStatusType === 'REQUESTED' ? '' : row.modifierName"></td>
        <td class="center" @click="popupIssueModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.licenseSerialNo }}</td>
        <td class="center" v-if="isAdmin == 'Y'" style="cursor: pointer; text-overflow:ellipsis;"><Checkbox v-model="row.useYn" trueValue="Y" @change="onCheckFocus(row)" invalid binary /></td>   
      </tr>
     </tbody>
    </table>
  </div>
 
  <!-- 본문 모바일 -->
  <div class="container" v-if="isMobile == true">
  	<Accordion class="accordion-custom" :activeIndex="0">
	    <AccordionTab v-for="(row, i) in license" :key="i">
	    	<template #header>
          <span>{{row.companyName + ' - ' + row.projectName}}<b :class="row.issueStatusType === 'REQUESTED' ? 'text-danger' : 'text-primary'" >({{ row.issueStatusTypeName }})</b>
            <br/><span v-if="row.ipAddress !== null">(<span v-html="row.ipAddress !== null ? row.ipAddress.replaceAll(',', ',<br/>') : '-'"></span>)</span>
          </span>
        </template>
        <p>일련번호 : {{ row.licenseSerialNo }}</p>
        <p>발급제품 : <span v-html="row.issuedProdName !== null ? row.issuedProdName.replaceAll(',', ',<br/>') : '-'"></span></p>
        <p v-if="row.partnerYn === 'Y'">번들사 : {{ row.partnerName }}</p>
        <p>CPU : {{ row.cpuInfo }}</p>
        <p>IP : <span v-html="row.ipAddress !== null ? row.ipAddress.replaceAll(',', ',<br/>') : '-'"></span></p>
        <p>서버구분 : {{ row.serverTypeName }}</p>
        <p>타입 : {{ row.periodTypeName }}</p>
        <p v-if="row.periodType === 'TIME_LIMIT'">시작일 : {{ row.startDate }}</p>
        <p v-if="row.periodType === 'TIME_LIMIT'">만료일 : {{ row.endDate }}</p>
        <!-- <p>요청부서 : {{ row.requestDeptName }}</p> -->
        <p>진행상태 : {{ row.issueStatusTypeName }}</p>
        <p>발급자 : {{ row.modifierName }}</p>
		    <button class="btn btn-primary" @click="popupIssueModal(row)" style="margin-right: 5px">발급</button>        
        <button class="btn btn-warning" @click="copyKey(row)">키 복사</button>
	    </AccordionTab>
	  </Accordion>
  </div>
  
  <div class="container">
    <LoadingBar :loading= "loading" v-if="loading == true"></LoadingBar>
	  <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
	  <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
  </div>
</template>

<script>
import LicenseIssue from './IssueNoRequest.vue'
import LicenseSerialNoUpdate from './LicenseSerialNoUpdate.vue'
import LicenseInfoRegist from './RegistNoRequest.vue'
import LoadingBar from '@/components/Common/LoadingBar.vue'
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

export default {
  name: 'LicenseMain',
  data() {
    return {
      licenseIssueModal: false,
      licenseSerialNoUpdateModal: false,
      licenseInfoRegistModal: false,
      license: {},
      header: ["고객사", "프로젝트", "발급제품", "서버구분", "CPU", "IP", "만료일", "만든사람", "수정한사람", "일련번호"],
      detailsLicenseSeq: "",
      update: false,
      searchKeywordProject: '',
      searchKeywordCompany: '',
      searchKeywordIP: '',
      searchKeywordLicenseSerialNo: '',
      searchKeyword: '',
      companys: [],
      filteredCompanys: [],
      projects: [],
      filteredProjects: [],      
      serverType: "",
      issueStatusType: "",
      ipType: "",
      serverTypes: {},
      issueStatusTypes: {},
      ipTypes: {},
      currentPage: 1,        // 현재 페이지
      itemsPerPage: 10,      // 페이지당 항목 수
      total: 0,      // 페이지당 항목 수
      detailsProjectUid : "",
      detailsCompanySeq : "",
      detailsLicenseSerialNo : "",
      pagination: {
    		endRow: 0,
		    hasNextPage: false,
		    hasPreviousPage: false,
		    isFirstPage: true,
		    isLastPage: true,
		    navigateFirstPage: 0,
		    navigateLastPage: 0,
		    navigatePages: 10,
		    navigatepageNums: [],
		    nextPage: 0,
		    pageNum: 1,
		    pageSize: this.isMobile ? 5 : 10,
		    pages: 0,
		    prePage: 0,
		    size: 0,
		    startRow: 0,
		    total: 0
    	},
      hoverText: '',
      isEngineer: false,
      isAdmin: 'N',
      loading: false,
    };
  },
  props: {
  	isMobile: Boolean,
    engineerRoleCheck: Boolean,
  },
  components: {
    LicenseIssue,
    LicenseSerialNoUpdate,
    LicenseInfoRegist,
    LoadingBar,
    Pagination,
    mPagination,
  },
  created() {    
  	var param = {
    };
    
    this.$get('/biz/company/list',  {
	            	params: param
	       		 },
                function(response, i){
                   	i.companys = response.data.result;   //기존데이터에 추가                    
                },
                function(e, i){
                	console.log(e);
                }
     );
  
  	this.$code(["SERVER_TYPE", "ISSUE_STATUS_TYPE", "IP_TYPE"], false,
		function(result, i){
           i.serverTypes = result.serverType;
           i.issueStatusTypes = result.issueStatusType;
           i.ipTypes = result.ipType;
        },
        function(e, i){
        	console.log(e);
        }
	);

  this.$post(`/biz/account/${this.$store.getters.getUserEmail}/team/get`, null,
              function(response, i){
                i.isAdmin = response.data.adminYn;
              },
              function(e, i){
              	console.log(e);
              }
    );    
  }, 
  watch: {    
    update(update) {      
      if (update == true) {
        var pageSize = this.isMobile ? 5 : 10;
        this.changePage(this.currentPage, pageSize);
        this.update = false;
      }
    }
  },
  computed: {    
  },
  methods: {
    // 페이지 변경 시 호출될 함수
    changePage(newPage, pageSize) {
      this.currentPage = newPage;
      this.loading = true;
      var paramData = {
    	"pageNum": newPage,
      "pageSize": pageSize,
      "searchKeyword": this.searchKeyword,
      "searchKeywordProject": this.searchKeywordProject.projectName != undefined ? this.searchKeywordProject.projectName : this.searchKeywordProject != "" ? this.searchKeywordProject : "",
      "searchKeywordCompany": this.searchKeywordCompany.companyName != undefined ? this.searchKeywordCompany.companyName : this.searchKeywordCompany != "" ? this.searchKeywordCompany : "",
      "searchKeywordIP": this.searchKeywordIP,
      "searchKeywordLicenseSerialNo": this.searchKeywordLicenseSerialNo,
      "serverType": this.serverType != "" ? this.serverType.value : "",
      "issueStatusType": this.issueStatusType != "" ? this.issueStatusType.value : "",
      "ipType": this.ipType != "" ? this.ipType.value : "",
      "isAdmin":this.isAdmin,
	    };     
    this.$get('/biz/license/list', {
	            	params: paramData
	       		 },
                function(response, i){
                	i.license = response.data.pagination.list;   //기존데이터에 추가
                	i.pagination = response.data.pagination;
                  i.header = i.isAdmin == 'Y' ? i.header = ["고객사", "프로젝트", "발급제품", "서버구분", "CPU", "IP", "만료일", "만든사람", "수정한사람", "일련번호", "사용여부"] : i.header = ["고객사", "프로젝트", "발급제품", "서버구분", "CPU", "IP", "만료일", "만든사람", "수정한사람", "일련번호"]
                  i.pagination.total == 0 ? i.$messageShow('조회된 값이 없습니다.', 'warn') : "";
                  i.loading = false;  
                },
                function(e, i){
                	console.log(e);
                  i.loading = false;  
                }
     );
     
    },
    popupIssueModal(row){
    	this.licenseIssueModal = true;
    	this.detailsProjectUid = row.projectUid;
    	this.detailsCompanySeq = row.companySeq;
    	this.detailsLicenseSeq = row.licenseSeq;
    },
    popupIssueModal_Regist(){
    	this.licenseIssueModal = true;
    	this.detailsProjectUid = "";
    	this.detailsCompanySeq = "";
    	this.detailsLicenseSeq = "";
    },
    popupLicenseSerialNoUpdateModal(row){
    	this.licenseSerialNoUpdateModal = true; 
    	this.detailsProjectUid = row.projectUid; 
    	this.detailsCompanySeq = row.companySeq; 
    	this.detailsLicenseSeq = row.licenseSeq;
    	this.detailsLicenseSerialNo = row.licenseSerialNo;
    },
    unsetSearchData(){
    	this.searchKeyword = "";
	    this.searchKeywordProject = "";
	    this.searchKeywordCompany = "";
	    this.searchKeywordIP = "";
	    this.serverType = "";
	    this.issueStatusType = "";
	    this.ipType = "";
    },
    setProjectList() {
    	
    	this.searchKeywordProject = "";
    	if(this.searchKeywordCompany.companySeq != undefined){
			var param = {
				companySeq : this.searchKeywordCompany.companySeq,
			};
	    
			this.$get('/biz/project/list',  {
			            	params: param
				 },
			        function(response, i){
			    		i.projects = response.data.result;   //기존데이터에 추가
			        },
			        function(e, i){
			        	console.log(e);
			        }
			);
		}
            
    },
    companySearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCompanys = this.companys.filter((company) => company.useYn === 'Y');
                } else {
                    this.filteredCompanys = this.companys.filter((company) => {
                      return company.useYn === 'Y' && company.companyName.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
            
            
    },
    projectSearch(event) {
             setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredProjects = this.projects.filter((project) => project.useYn === 'Y');
                } else {
                    this.filteredProjects = this.projects.filter((project) => {
                      return project.useYn === 'Y' &&  project.projectName.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);            
    },
    setHoverText(text) {
      this.hoverText = text;
    },
    clearHoverText() {
      this.hoverText = '';
    },
    exportXls() {
            var paramData = {
              "pageNum": 1,
              "pageSize": 1000000,
              "searchKeyword": this.searchKeyword,
              "searchKeywordProject": this.searchKeywordProject.projectName != undefined ? this.searchKeywordProject.projectName : this.searchKeywordProject != "" ? this.searchKeywordProject : "",
              "searchKeywordCompany": this.searchKeywordCompany.companyName != undefined ? this.searchKeywordCompany.companyName : this.searchKeywordCompany != "" ? this.searchKeywordCompany : "",
              "searchKeywordIP": this.searchKeywordIP,
              "searchKeywordLicenseSerialNo": this.searchKeywordLicenseSerialNo,
              "serverType": this.serverType != "" ? this.serverType.value : "",
              "issueStatusType": this.issueStatusType != "" ? this.issueStatusType.value : "",
              "ipType": this.ipType != "" ? this.ipType.value : "",
            };
            this.$get('/biz/license/list', {
                        params: paramData
                    },
                    function(response, i){
                      const data = response.data.pagination.list;                      
                      const worksheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(data)));
                      const workbook = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

                      saveAs(dataBlob, '라이선스_내역.xlsx');
                    },
                    function(e, i){
                      console.log(e);
                    }
            );
    },
    onCheckFocus(row){      
      var self = this;
      if(this.isAdmin == 'Y'){      
        var param = {	          
            "companySeq" : row.companySeq,
            "projectUid" : row.projectUid,
            "licenseSeq" : row.licenseSeq,
            "useYn" : row.useYn == false ? 'N' : 'Y'
          };
        this.$post(`/biz/license/mod/use-yn`, param,
                function(response, i){
                  self.$messageShow('수정되었습니다.', 'success');                 
                },
                function(e, i){
                  console.log(e);
                }
        );
      } else {
        this.$messageShow('권한이 없습니다.(관리자만 가능)', 'warn');
        if(row.useYn == false) {
          row.useYn = 'Y';
        } else {
          row.useYn = '';
        }
      }    
    },
    copyKey(row){
      navigator.clipboard.writeText(row.issuedLicense);      
      alert("클립보드에 복사되었습니다!");
    } 
  }
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center;  
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}
}

th{
  text-align: center;
}

.pi{
  min-width: 56px;
  width: 56px;
  min-height: 50px;
  height: 50px;
}
</style>