<template>
  <transition name="fade" @closesModal="registModal = false" @update="update = true">
    <Regist v-if="registModal" :supportNames="supportNames" :update="update"  :isMobile="isMobile"/>
  </transition>
  <transition name="fade" @closesModal="supportDetailModal = false" @update="update = true">
    <SupportDetail :supportNames="supportNames" :supportDetailsKey="supportDetailsKey" :p_supportCategory="supportCategory" :isAdmin="isAdmin" :update="update" :isMobile="isMobile" v-if="supportDetailModal" />
  </transition>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">기술지원 이력</h1>
  </div>

  <!-- 검색 조건 -->
  <div class="container" v-if="isMobile == false">
    <input v-model="searchKeywordCompany" class="form-control" type="text" placeholder="고객사명" @keyup.enter="changePage(1, this.pagination.pageSize)" />  
    <input v-model="searchKeywordProject" class="form-control" type="text" placeholder="프로젝트명" @keyup.enter="changePage(1, this.pagination.pageSize)"  />  
    <input v-model="searchKeywordSupportName" class="form-control" type="text" placeholder="작업자" @keyup.enter="changePage(1, this.pagination.pageSize)"  style="max-width: 100px;"/>        
    <Dropdown v-model="searchKeywordSupportCategory" editable  :options="supportCategorys" optionLabel="label" placeholder="지원방법" class="w-full md:w-14rem" style="min-width: 140px;"/>  
    <Dropdown v-model="searchKeywordSupportCategoryDetail" editable  :options="supportCategoryDetails" optionLabel="label" placeholder="지원구분" class="w-full md:w-14rem" style="min-width: 140px;"/>  
    <Calendar v-model="searchDate" selectionMode="range" :manualInput="false" dateFormat="yy/mm/dd" showButtonBar showIcon @update:model-value="updateCal" v-tooltip.top="{ value: t_SearchDate, autoHide: false }" style="width: 50%;"/>  
    <input v-model="searchKeywordReqResDetails" class="form-control" type="text" placeholder="요청 / 지원 내용" @keyup.enter="changePage(1, this.pagination.pageSize)"  />  
    <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>
    <Button class="pi pi-times-circle" severity="success" @click="unsetSearchData" @mouseover="setHoverText('검색 초기화')" @mouseout="clearHoverText" :title="hoverText"></Button>  
    <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('새로작성')" @mouseout="clearHoverText" :title="hoverText" @click="registModal = true"></Button>    
  </div>  

  <div class="container d-flex" v-if="isMobile == true" >
    <div class="d-flex" style="width:100%;">
	  <input v-model="searchKeyword" class="form-control" type="text" placeholder="고객사명 / 프로젝트 / 요청,지원내용"  @keyup.enter="changePage(1, this.pagination.pageSize)" style="font-size:0.75rem"/>
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>
      <Button class="pi pi-pencil" severity="warning" @mouseover="setHoverText('새로작성')" @mouseout="clearHoverText" :title="hoverText" @click="registModal = true"></Button>    
    </div>
  </div>

  <!-- 본문 -->
  <div class="container" v-if="isMobile == false">	    
	  <table class="table table-bordered table-hover caption-top">
	    <thead class="table-light">
	      <tr class="table-primary">
	        <th scope="col" v-for="item in header" :key="item" class="center">
	          {{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr v-for="(row, i) in supportDetailsList" :key="i">
          <td class="center" @click="popupDetailModal(row)" @mouseover="setHoverText(row.companyName)" @mouseout="clearHoverText" :title="hoverText" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportDetailsKey }}</td>
          <td class="center" @click="popupDetailModal(row)" @mouseover="setHoverText(row.companyName)" @mouseout="clearHoverText" :title="hoverText" style="cursor: pointer; text-overflow:ellipsis;">{{ row.companyName.length > 10 ? row.companyName.substring(0,10) + '...' : row.companyName }}</td>
          <td class="center" @click="popupDetailModal(row)" @mouseover="setHoverText(row.projectName)" @mouseout="clearHoverText" :title="hoverText" style="cursor: pointer; text-overflow:ellipsis;">{{ row.projectName.length > 20 ? row.projectName.substring(0,20) + '...' : row.projectName }}</td>
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.visitDate }}</td>       
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportCategory }}</td>   
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;"><i class="pi pi-paperclip" v-if="row.supportCategoryDetail == '설치' && row.supportDetailsInstallDOC != null && row.supportDetailsInstallDOC != ''" style="min-width:0px;min-height:0px;height:15px;width:15px;margin-right: 2px;" />{{ row.supportCategoryDetail }}</td>   
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportProd }}</td>   
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.serverType }}</td>   
          <td class="center" @click="popupDetailModal(row)" @mouseover="setHoverText(row.supportNames)" @mouseout="clearHoverText" :title="hoverText" style="cursor: pointer; text-overflow:ellipsis; width:120px">{{ row.supportNames.split(',').length > 1 ? row.supportNames.split(',')[0] + " 외 " + (row.supportNames.split(',').length-1) + "명" : row.supportNames }}</td>            
          <td class="center" @click="popupDetailModal(row)" style="cursor: pointer; text-overflow:ellipsis;">{{ row.supportStatus }}</td>             
          <td class="center" v-if="isAdmin == 'Y'" style="cursor: pointer; text-overflow:ellipsis;"><Checkbox v-model="row.useYn" trueValue="Y" @change="onCheckFocus(row)" invalid binary /></td>   
        </tr>
      </tbody>
    </table>
  </div>
  <div class="container" v-if="isMobile == true">
  	<Accordion class="accordion-custom" :activeIndex="0">
	    <AccordionTab v-for="(row, i) in supportDetailsList" :key="i">
	    	<template #header>
          <span><B> {{ row.supportNames }} </B>
            {{ ' - ' + row.companyName }}<br>           
            {{ row.supportCategoryDetail == null ? '['+row.supportCategory+']' : '['+row.supportCategory + '-' +row.supportCategoryDetail+']' }} {{ '  ' + (row.supportCategory == '상주' ? row.visitDate + ' ~ ' + row.visitEndDate : row.visitDate) }}</span>
        </template>
        <p>프로젝트 : {{ row.projectName }}</p>
        <!-- <p>요청내용 : {{ row.requestDetails }}</p>
        <p>지원내용 : {{ row.responseDetails }}</p> -->
        <p>제품 : {{ row.supportProd == null ? "-" : row.supportProd }}</p>
        <p>서버구분 : {{ row.serverType == null ? "-" : row.serverType }}</p>
        <p v-if="row.supportCategory != '상주' && row.supportCategory != '비상주'">지원상태 : {{ row.supportStatus }}</p>
		    <button class="btn btn-primary" @click="popupDetailModal(row)" style="margin-right: 5px">수정</button>        
	    </AccordionTab>
	  </Accordion>
  </div>
  <div class="container">
    <LoadingBar :loading= "loading" v-if="loading == true"></LoadingBar>
	  <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
	  <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
    <Badge :value="pagination.total" severity="warning" v-if="isMobile == false" style="margin-top:20px"></Badge>
  </div>
</template>
<script>
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'
import LoadingBar from '@/components/Common/LoadingBar.vue'
import Regist from './Regist.vue'
import SupportDetail from './SupportDetail.vue'
export default {
name: 'MenuMain',
data(){
  return {
    pagination: {
    		endRow: 0,
		    hasNextPage: false,
		    hasPreviousPage: false,
		    isFirstPage: true,
		    isLastPage: true,
		    navigateFirstPage: 0,
		    navigateLastPage: 0,
		    navigatePages: 10,
		    navigatepageNums: [],
		    nextPage: 0,
		    pageNum: 1,
		    pageSize: this.isMobile ? 5 : 10,
		    pages: 0,
		    prePage: 0,
		    size: 0,
		    startRow: 0,
		    total: 0
    	},
      hoverText: '',
      isMobile : this.$isMobile(),
      registModal:false,
      supportNames : {},
      supportDetailsList: {},  
      searchKeywordProject: '',
      searchKeywordCompany: '',
      searchKeywordSupportCategory: '',
      searchKeywordSupportCategoryDetail: '',
      header: ["NO","고객사","프로젝트","지원일자","지원방법","지원구분","제품","서버구분","작업자","지원상태"],
      supportDetailModal : false,
    	supportDetailsKey : '',   
      update: false,   
      isAdmin: 'N',
      searchDate: null,
      startDate: null,
      endDate: null,     
      currentPage: 1,        // 현재 페이지
      loading: false,
      supportCategory: {},
      supportCategorys: {},
      supportCategoryDetails: {},
      t_SearchDate: '날짜 선택',
      loginUserName: '',
      searchKeyword: '',
  };
},
components: {
  Pagination,
  mPagination,
  Regist,
  SupportDetail,
  LoadingBar,
},
created(){
    this.pagination.pageSize= this.isMobile ? 5 : 10
    this.$post(`/biz/account/${this.$store.getters.getUserEmail}/team/get`, null,
            function(response, i){
              i.isAdmin = response.data.adminYn;
              i.loginUserName = response.data.name;
            },
            function(e, i){
              console.log(e);
            }
    );  
    
    this.$code(["SUPPORT_CATEGORY_DETAIL", "SUPPORT_CATEGORY"], false,
      function(result, i){
            i.supportCategorys = result.supportCategory;
            i.supportCategoryDetails = result.supportCategoryDetail;
          },
          function(e, i){
            console.log(e);
          }
    );
  
},
watch: {    
    update(update) {      
      if (update == true) {
        var pageSize = this.isMobile ? 5 : 10;
        this.changePage(this.currentPage, pageSize);
        this.update = false;
      }
    } 
},
computed: {
    
},
mounted() {
  this.$nextTick(function() {
    this.getTeamAccount();    
  });
  this.$calendar();    
},
methods: {
  // 페이지 변경 시 호출될 함수
  changePage(newPage, pageSize) {       
      this.currentPage = newPage;      
      var paramData = {
      "pageNum": newPage,
      "pageSize": pageSize,		
      "searchKeyword": this.searchKeyword,
      "searchKeywordProject": this.searchKeywordProject.projectName != undefined ? this.searchKeywordProject.projectName : this.searchKeywordProject != "" ? this.searchKeywordProject : "",
      "searchKeywordCompany": this.searchKeywordCompany.companyName != undefined ? this.searchKeywordCompany.companyName : this.searchKeywordCompany != "" ? this.searchKeywordCompany : "",
      "searchKeywordSupportName": this.searchKeywordSupportName != undefined ? this.searchKeywordSupportName : "",
      "searchKeywordSupportCategory": this.searchKeywordSupportCategory.label != undefined ? this.searchKeywordSupportCategory.label : "",
      "searchKeywordSupportCategoryDetail": this.searchKeywordSupportCategoryDetail.label != undefined ? this.searchKeywordSupportCategoryDetail.label : "",
      "searchKeywordReqResDetails": this.searchKeywordReqResDetails != undefined ? this.searchKeywordReqResDetails : "",
      "isAdmin":this.isAdmin,
      "loginUserName":this.loginUserName,
      "startDate": this.searchDate != null ? this.$simpleDateTimeFormat(this.searchDate[0], "yyyy/MM/dd") : "",
      "endDate":this.searchDate != null && this.searchDate[1] != null ? this.$simpleDateTimeFormat(this.searchDate[1], "yyyy/MM/dd") : "",
    };     
    this.loading = true;
    this.$get('/biz/support/list', {
	            	params: paramData
	       		  },              
              function(response, i){                
                i.supportDetailsList = response.data.pagination.list;   //기존데이터에 추가                              
                i.pagination = response.data.pagination;                
                i.header = i.isAdmin == 'Y' ? i.header = ["NO","고객사","프로젝트","지원일자","지원방법","지원구분","제품","서버구분","작업자","지원상태","사용여부"] : i.header = ["NO","고객사","프로젝트","지원일자","지원방법","지원구분","제품","서버구분","작업자","지원상태"]
                i.loading = false;                
                i.pagination.total == 0 ? i.$messageShow('조회된 값이 없습니다.', 'warn') : "";
                i.pagination.pageSize = i.isMobile ? 5 : 10;
              },
              function(e, i){
                i.loading = false;
                console.log(e);
              }
     );
  },
  setHoverText(text) {
    this.hoverText = text;
  },
  clearHoverText() {
    this.hoverText = '';
  },
  getTeamAccount() {
    var paramData = {
          "teamCode": "TST"
    };     
    this.$get('/biz/account/teamlist', {
	            	params: paramData
	       		 },
                function(response, i){
                	i.supportNames = response.data.teamResult;   //기존데이터에 추가                  
                },
                function(e, i){
                	console.log(e);
                }
     );
  },
  unsetSearchData(){    	
	    this.searchKeywordProject = "";
	    this.searchKeywordCompany = "";	    
      this.searchKeywordSupportName = "";
      this.searchDate= null;
      this.searchKeywordReqResDetails = "";
      this.t_SearchDate = "날짜 선택";
      this.searchKeywordSupportCategory = "";
      this.searchKeywordSupportCategoryDetail = "";
  },
  popupDetailModal(row){
      this.supportDetailModal = true;
      this.supportDetailsKey = row.supportDetailsKey;
      this.supportCategory = row.supportCategory;
  },  
  onCheckFocus(row){    
    var self = this;
    if(this.isAdmin == 'Y'){      
      var param = {	          
          "supportDetailsKey" : row.supportDetailsKey,
          "useYn" : row.useYn == false ? 'N' : 'Y'
        };
      this.$post(`/biz/support/mod/use-yn`, param,
              function(response, i){
                self.$messageShow('수정되었습니다.', 'success');                 
              },
              function(e, i){
              	console.log(e);
              }
      );
    } else {
      this.$messageShow('권한이 없습니다.(관리자만 가능)', 'warn');
      if(row.useYn == false) {
        row.useYn = 'Y';
      } else {
        row.useYn = '';
      }
    }    
  }, 
  updateCal(value){    
    if(value == null) {
      this.searchDate = null;
      this.t_SearchDate = null;
    }
    else if(value[1] == null){
      this.t_SearchDate = this.$simpleDateTimeFormat(value[0], "yyyy/MM/dd");
    } else {
      this.t_SearchDate = "시작일 : "+this.$simpleDateTimeFormat(value[0], "yyyy/MM/dd") + '\n' + "종료일 : "+this.$simpleDateTimeFormat(value[1], "yyyy/MM/dd"); 
    }
  },
}
};
</script>

<style lang="css" scoped>
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}
}

th{
  text-align: center;
}

.pi{
  min-width: 56px;
  width: 56px;
  min-height: 50px;
  height: 50px;
}
</style>